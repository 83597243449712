import React, { useEffect, useMemo, useState } from 'react';

import { request, useAuth } from '#api';
import Form, { InputText, InputMoney, Select } from '#Form';
import Dialog from '#Dialog';
import DialogTitle from './DialogTitle';

import {
	reqCompensationOptions,
} from './schemas'

export const SparepartsForm = ({ _id, handleClose }) => {
	const auth = useAuth()
	const [ data, setData ] = useState({})
	const [ isExchange, setExchange ] = useState(false)
	const [ isCreditNote, setCreditNote ] = useState(false)

	useEffect(() => {
		_id && request('/cabinet-exchange/spare-parts/get', { _id }).then(data => {
			setExchange(data?.reqCompensation === 'exchange')
			setCreditNote(data?.reqCompensation === 'credit-note')
			setData(data)
		})
	}, [ _id ])

	const onSubmit = async () => {
		await request('/cabinet-exchange/spare-parts/set', { ...data, _id })
		handleClose?.()
	}

	const aclRW = auth.check('cabinetexchange-spareparts')

	const formProps = {
		_id,
		data, setData,
		onSubmit: _id && aclRW && onSubmit,
		submitLabel: 'Save',
	}

	return (
		<Form {...formProps}>
			<Select label="Desired compensation" name="reqCompensation" options={reqCompensationOptions} required disabled />
			{ isExchange && <InputText label="OC delivery number" name="spareParts.ocDeliveryNumber" disabled={!aclRW} /> }
			<InputText label="OC return number" name="spareParts.ocReturnNumber" disabled={!aclRW} />
			{ isCreditNote && <InputText label="Credit note number" name="spareParts.creditNoteNumber" disabled={!aclRW} /> }
			{ isCreditNote && <InputMoney label="Credit note amount" name="spareParts.creditNoteAmount" disabled={!aclRW} /> }
		</Form>
	)
}

export const SparepartsDialog = ({ _id, item, handleClose }) => {
	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title="Spare parts" />}
			width="md"
		>
			<SparepartsForm _id={_id} handleClose={handleClose} />
		</Dialog>
	)
}
