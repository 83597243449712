import { useEffect, useMemo, useState } from "react"
import { eventDispatch, eventOn, eventOff } from "./eventBus"
import Keycloak from 'keycloak-js'
import config from "./config"
import { setVar } from "./api"

const SESSION_TOKEN_VAR = 'sessToken'
const SESSION_REFRESH_THRESHOLD = 45
const SESSION_REFRESH_INTERVAL = 30 * 1000
const {
	auth = {},
} = config || {}

let keycloak = undefined
export let sessionToken = undefined

export const setAuthToken = (token, tokenParsed) => {
	sessionToken = token
	eventDispatch('auth-token-changed', {
		token,
		tokenParsed,
	})
}

export const useAuth = () => {
	const [ authStatus, setAuthStatus ] = useState(null)

	const refreshSession = async (status) => {
		const token = status?.token
		const info = status?.tokenParsed
		setVar(SESSION_TOKEN_VAR, Boolean(token))
		setAuthStatus({ token, info })
	}

	useEffect(() => {
		keycloak?.token && refreshSession(keycloak)

		const cbExec = e => refreshSession(e.detail || {})
		eventOn('auth-token-changed', cbExec)
		return () => eventOff("auth-token-changed", cbExec)
	}, [])

	return useMemo(() => {
		return new Auth(authStatus)
	}, [ authStatus ])
}

export class Auth {
	constructor(authStatus) {
		const info = authStatus?.info || {}
		this.uid = info?.uid || undefined
		this.mid = info?.mid || undefined
		this.uname = info?.preferred_username || undefined
		this.roles = info?.resource_access?.[auth.clientID]?.roles || []
		this.groups = info?.groups || []
	}

	check = role => {
		if(!this.roles?.length)
			return false
		if(role)
			return Boolean(this.roles.includes(role))
		return true
	}
}

if(auth.clientID) {
	keycloak = new Keycloak({
		url: auth.url,
		realm: auth.realm,
		clientId: auth.clientID,
	})

	keycloak.init({ onLoad: 'login-required', checkLoginIframe: true })
		.then(authenticated => {
			if(authenticated) {
				setAuthToken(keycloak.token, keycloak.tokenParsed)
				keycloakAutorefresh()
			} else
				keycloak.login()
		})
		.catch(error => {
			console.error("AUTH init error", error)
		})
}

const keycloakAutorefresh = () => setInterval(() => {
	keycloak.updateToken(SESSION_REFRESH_THRESHOLD)
		.then(refreshed => {
			if(refreshed) {
				const currentTime = Math.floor(Date.now() / 1000)
				const tokenExpirationTime = keycloak.tokenParsed.exp
				const remainingTime = tokenExpirationTime - currentTime

				if(remainingTime < SESSION_REFRESH_THRESHOLD)
					keycloak.login()
				else
					setAuthToken(keycloak.token, keycloak.tokenParsed)
			}
		})
		.catch(() => {
			keycloak.login()
		})
}, SESSION_REFRESH_INTERVAL)
