import React, { useState, useEffect } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { request, requestDownload, useAuth } from '#api';
import { TplWSidebar as Tpl } from '#Template'
import { Box, Typography } from '@mui/material';
import EventLog from './EventLog'
import Button from '#Button';
// import { dialogConfirm } from '#Dialog';
import Search, { SearchText } from '#Search'
import { displayDate } from '#Display';
import Data from '#Data';
import { statuses, actionsDef, producerOptions } from './schemas'
import { RequestDialog } from './EditForm';
import { ActionDialog, TimelineDialog } from './Timeline';
import { SparepartsDialog } from './SparepartsForm';
import ButtonPad from '#ButtonPad';
// import { ApprovalDialog } from './ApprovalForm';

const useActions = ({ handleRefresh, setRequestHistory, setEdit, setTimeline, setSpareparts, setActionDialog }) => {
	const auth = useAuth()

	return ({ _id, row, handleRowRefresh }) => {
		// const handleTrigger = action => dialogConfirm({
		// 	msg: action.confirmMsg,
		// 	onConfirm: async () => {
		// 		await request('/cabinet-exchange/action/trigger', {
		// 			_id,
		// 			action: action.tag,
		// 		})
		// 		await handleRefresh()
		// 		await handleRowRefresh()
		// 	}
		// })

		const actions = [
			{
				label: "View",
				icon: <PreviewIcon />,
				onClick: () => setEdit({ _id, row }),
			},
			{
				label: "Log",
				icon: <ManageSearchIcon />,
				onClick: () => setRequestHistory({ _id, row }),
			},
			{
				label: "Timeline",
				icon: <ViewTimelineIcon />,
				onClick: () => setTimeline({ _id, row }),
			},
		]

		if(row?.actions?.length)
			actions.push({
				label: "New action",
				icon: <PublishedWithChangesIcon />,
				onClick: () => setActionDialog({ _id, row }),
			})
		// if(row?.actions?.includes('product-support-approve') && row?.actions?.includes('product-support-reject'))
		// 	actions.push({
		// 		label: "Product support Approval",
		// 		icon: <PublishedWithChangesIcon />,
		// 		onClick: () => setPSApproval({ _id, row }),
		// 	})
		// if(row?.actions?.includes('service-management-approve') && row?.actions?.includes('service-management-reject'))
		// 	actions.push({
		// 		label: "MGT Approval",
		// 		icon: <PublishedWithChangesIcon />,
		// 		onClick: () => setMGTApproval({ _id, row }),
		// 	})
		if(row?._state['service-management-approved'])
			actions.push({
				label: "Spare parts",
				icon: <EngineeringIcon />,
				onClick: () => setSpareparts({ _id, row }),
			})
		// actionsDef
		// 	.filter(action => row?.actions.includes(action.tag))
		// 	.filter(action => action.confirmMsg)
		// 	.forEach(action => actions.push({
		// 		label: action.label,
		// 		icon: action.icon,
		// 		onClick: () => handleTrigger(action),
		// 	}))

		return (
			<ButtonPad def={actions} />
		)
	}
}

const RefNumber = ({ row }) => (
	<>
		<Typography variant='body2'>{row?.device?.serial}</Typography>
		<Typography variant='caption'>{producerOptions?.find(opt => opt.value === row?.device?.producer)?.label}</Typography>
	</>
)

const Customer = ({ row }) => (
	<>
		<Typography variant='body2'>{row?.customer?.name}</Typography>
		<Typography variant='caption'>{row?.store?.country}</Typography>
	</>
)

const Status = ({ row }) => (
	<Typography>
		{statuses?.find(status => status.value === row?.status)?.icon || null}
		{statuses?.find(status => status.value === row?.status)?.label || '???'}
	</Typography>
)

const tableDef = [
	{
		label: 'Num',
		content: ({ row }) => row?._registry.num,
	},
	{
		label: 'Registered on',
		content: ({ row }) => displayDate(row?._state.created),
	},
	{
		label: 'Reporting date',
		content: ({ row }) => row?.notificationToAHTDate?.split('-').reverse().join('/')
	},
	{ label:'Reference number', content:RefNumber },
	{ label:'Customer', content:Customer },
	{
		label: 'Product support',
		content: ({ row }) => {
			const logEntry = row?.log?.items?.find(logEntry =>
				actionsDef?.find(action => action.productSupportRelated && action.tag === logEntry.actionTag)
			)
			return logEntry?.uname || '-'
		},
	},
	{ label:'Status', content:Status },
]

export default function CabinetExchangeList() {
	const [ scan, setScan ] = useState({})
	const [ edit, setEdit ] = useState(null)
	const [ timeline, setTimeline ] = useState(null)
	const [ spareparts, setSpareparts ] = useState(null)
	// const [ psApproval, setPSApproval ] = useState(null)
	// const [ mgtApproval, setMGTApproval ] = useState(null)
	const [ actionDialog, setActionDialog ] = useState(null)
	const [ requestHistory, setRequestHistory ] = useState(null)
	const [ filterString, setFilterString ] = useState('')

	const handleRefresh = () => request('/cabinet-exchange/list', { filterString }).then(setScan)

	useEffect(() => {
		handleRefresh()
	}, [ filterString ])

	const Actions = useActions({ handleRefresh, setRequestHistory, setEdit, setTimeline, setSpareparts, setActionDialog })
	const fetchRow = async (_id) => {
		const row = await request('/cabinet-exchange/get', { _id })
		const log = await request('/cabinet-exchange/action/log', { _id })
		return { ...row, log }
	}

	return (
		<Tpl title='Requests archive'>
			<EventLog _id={requestHistory?._id} item={requestHistory?.row} handleClose={() => setRequestHistory()} />
			<RequestDialog _id={edit?._id} item={edit?.row} handleClose={() => setEdit()} />
			<TimelineDialog _id={timeline?._id} item={timeline?.row} handleClose={() => setTimeline()} />
			<SparepartsDialog _id={spareparts?._id} item={spareparts?.row} handleClose={() => setSpareparts()} />
			{/* <PSApprovalDialog _id={psApproval} handleClose={() => setPSApproval()} /> */}
			{/* <ApprovalDialog
				_id={psApproval}
				handleClose={() => setPSApproval()}
				title = 'Product support approval'
				endpoint="/cabinet-exchange/product-support/approval"
			/>
			<ApprovalDialog
				_id={mgtApproval}
				handleClose={() => setMGTApproval()}
				title = 'Service management approval'
				endpoint="/cabinet-exchange/service-management/approval"
			/> */}
			<ActionDialog _id={actionDialog?._id} item={actionDialog?.row} handleClose={() => setActionDialog()} />

			<Search>
				<SearchText label="Cerca" name="filter-string" value={filterString} setValue={setFilterString} />
			</Search>

			<Box sx={{ textAlign:'right' }}>
				<Button
					label="Export CSV"
					icon={<FileDownloadIcon />}
					onClick={() => requestDownload('/cabinet-exchange/export/csv')}
				/>
			</Box>

			<Data
				def={tableDef}
				scan={scan}
				fetchRow={fetchRow}
				actions={Actions}
			/>
		</Tpl>
	)
}
